<template>
	<div class="media">
		<div class="banner">
			<div class="one">
				Branding&Com
				<br />
				munications
			</div>
			<div class="two">Welcome to the M3DAO branding page.</div>
			<div class="three">
				Please follow these guidelines when promoting M3DAO in marketing ,
				<br />
				including adervetising,articles,websites,and printed promotions.
			</div>
		</div>
		<div class="body">
			<div class="left">
				<div class="title1" ref="aaa">M3DAO Brand Logotype</div>
				<div class="type">Logotype</div>
				<img src="../assets/kedia/logo1.png" alt="" class="logo1" />
				<div class="font1">
					The logo of M3DAO is an important visual identifier of our brand. It conveys the core
					values of our company and lays the foundation for corporate information transmission and
					communication. Therefore, the standard graphics must be strictly followed and cannot be
					arbitrarily changed.
				</div>
				<div class="title2">Logo Space</div>
				<div class="font2">
					<p>
						A certain amount of space is needed around the logomark to prevent it from becoming
						cluttered by
					</p>
					<p>
						surrounding artwork, images, or the edge of a page. Below are the minimum spacings for
						both logomark and wordmark.
					</p>
				</div>
				<img src="../assets/kedia/logo2.png" alt="" class="logo2" />
				<div class="title3">No Use Cases</div>
				<div class="font3">Here are some examples you should never do with the M3DAO Logomark.</div>
				<div class="box1">
					<div class="item">
						<img src="../assets/kedia/item1.png" alt="" />
						<div>Don’t use gradient colors on your logo</div>
					</div>
					<div class="item">
						<img src="../assets/kedia/item2.png" alt="" />
						<div>Don’t use gradient colors on your logo</div>
					</div>
					<div class="item">
						<img src="../assets/kedia/item3.png" alt="" />
						<div>Don’t use gradient colors on your logo</div>
					</div>
					<div class="item">
						<img src="../assets/kedia/item4.png" alt="" />
						<div>Don’t use gradient colors on your logo</div>
					</div>
					<div class="item">
						<img src="../assets/kedia/item5.png" alt="" />
						<div>Don’t use gradient colors on your logo</div>
					</div>
					<div class="item">
						<img src="../assets/kedia/item6.png" alt="" />
						<div>Don’t use gradient colors on your logo</div>
					</div>
				</div>
				<div class="box2" ref="bbb">
					<div class="left">Download</div>
					<div @click="go('/M3LOGO.zip')" class="right">
						<img class="down" src="../assets/kedia/down.svg" alt="" />
						<img class="h5down" src="../assets/h5kedia/down.png" alt="" />
						<span>Download m3DAO Logo</span>
					</div>
				</div>
				<div class="box3">
					<div class="left">
						<div class="top">Full Logo</div>
						<div class="bottom">
							<div @click="go('/png.png.zip')" class="button">PNG</div>
							<div @click="go('/svg.svg.zip')" class="button">SVG</div>
						</div>
					</div>
					<img src="../assets/kedia/logo3.png" alt="" class="logo3" />
				</div>
			</div>
			<div class="navright">
				<div
					class="item"
					:class="{ active: active == 'aaa' ? true : false }"
					@click="scrollToAnchor('aaa')"
				>
					M3DAO Brand Logotype
				</div>
				<div
					class="item"
					:class="{ active: active == 'bbb' ? true : false }"
					@click="scrollToAnchor('bbb')"
				>
					Download
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref } from 'vue';
const aaa = ref(null);
const bbb = ref(null);
const active = ref('aaa');
const scrollToAnchor = (ref) => {
	active.value = ref;
	if (ref == 'aaa') {
		aaa.value.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
	} else if (ref == 'bbb') {
		bbb.value.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
	}
};
const go = (url) => {
	window.open(url, '_blank');
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 768px) {
	.media {
		margin-top: -50px;
		width: 100%;
		box-sizing: border-box;
		background: rgba(0, 0, 0, 1);
		color: #ffffff;

		.banner {
			width: 100%;
			height: 372px;
			background: url('../assets/h5kedia/banner.png');
			background-size: 100% 372px;
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			.one {
				width: 300px;
				font-size: 36px;
				font-weight: 700;
				margin-top: 103px;
			}
			.two {
				font-size: 14px;
				font-weight: 400;
				margin-top: 17px;
			}
			.three {
				margin-top: 30px;
				width: 320px;
				font-size: 14px;
				font-weight: 400;
				line-height: 18px;
			}
		}

		.body {
			width: 100%;
			padding: 30px 20px 0;
			display: flex;
			flex-direction: column;
			// justify-content: space-between;
			position: relative;
			.left {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				.title1 {
					font-weight: 700;
					font-size: 24px;
					margin-bottom: 30px;
				}
				.type {
					font-weight: 600;
					font-size: 32px;
					margin-bottom: 50px;
				}
				.logo1 {
					width: 170px;
					height: 80px;
					margin-bottom: 48px;
				}
				.font1 {
					font-weight: 400;
					font-size: 16px;
					margin-bottom: 54px;
					// width: 800px;
				}
				.title2 {
					font-weight: 600;
					font-size: 20px;
					margin-bottom: 20px;
				}
				.font2 {
					font-weight: 400;
					font-size: 16px;
					margin-bottom: 54px;
				}
				.logo2 {
					width: 297px;
					height: 205px;
					margin-bottom: 103px;
				}
				.title3 {
					width: 100%;
					font-weight: 600;
					font-size: 22px;
					margin-bottom: 20px;
				}
				.font3 {
					font-weight: 400;
					font-size: 16px;
					margin-bottom: 40px;
					// width: 700px;
				}
				.box1 {
					width: 100%;
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					font-size: 16px;
					.item {
						width: 100%;
						display: flex;
						flex-direction: column;
						align-items: center;
						margin-bottom: 20px;
						img {
							width: 100%;
							height: 184px;
							margin-bottom: 20px;
						}
					}
				}
				.box2 {
					width: 100%;
					height: 68px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 40px;
					.left {
						display: none;
						width: 276px;
						height: 60px;
						font-size: 54px;
						font-weight: 700;
					}
					.right {
						width: 240px;
						height: 46px;
						display: flex;
						align-items: center;
						justify-content: center;
						border: 0;
						background: rgba(253, 170, 51, 1);
						border-radius: 3px;
						font-weight: 600;
						font-size: 16px;
						// color: #000000;
						.down {
							display: none;
						}
						.h5down {
							margin-right: 6px;
							width: 26px;
						}
					}
				}
				.box3 {
					width: 100%;
					height: 274px;
					background: #1e1e1e;
					display: flex;
					flex-direction: column-reverse;
					justify-content: center;
					align-items: center;
					padding: 30px 20px;
					.left {
						width: 100%;
						margin-top: 22px;
						// width: 290px;
						// height: 104px;
						.top {
							font-size: 22px;
							font-weight: 400;
							margin-bottom: 22px;
						}
						.bottom {
							width: 100%;
							display: flex;
							justify-content: space-between;

							.button {
								width: 134px;
								height: 44px;
								font-size: 18px;
								font-weight: 600;
								border-radius: 40px;
								border: 1px solid #fdaa33;
								text-align: center;
								line-height: 44px;
								color: #fdaa33;
							}
						}
					}
					.right {
						width: 160px;
						height: 74px;
					}
				}
			}
			.navright {
				display: none;
			}
		}
	}
}

/* 当前屏幕大于768px 应用媒体查询里面的样式 */
@media screen and (min-width: 768px) {
	.media {
		width: 100%;
		box-sizing: border-box;
		background: rgba(21, 21, 21, 1);
		color: #ffffff;

		.banner {
			width: 100%;
			height: 460px;
			background: url('../assets/kedia/banner.png');
			.one {
				font-size: 54px;
				font-weight: 700;
				padding: 120px 0 67px 262px;
			}
			.two {
				font-size: 32px;
				font-weight: 600;
				padding: 0 0 40px 267px;
			}
			.three {
				min-width: 676px;
				font-size: 20px;
				font-weight: 400;
				padding: 0 0 0 267px;
			}
		}
		.body {
			width: 100%;
			padding: 108px 270px 100px;
			display: flex;
			justify-content: space-between;
			position: relative;
			.left {
				width: 840px;

				.title1 {
					font-weight: 700;
					font-size: 54px;
					margin-bottom: 56px;
				}
				.type {
					font-weight: 600;
					font-size: 32px;
					margin-bottom: 56px;
				}
				.logo1 {
					width: 170px;
					height: 80px;
					margin-bottom: 58px;
				}
				.font1 {
					font-weight: 400;
					font-size: 20px;
					margin-bottom: 164px;
					width: 800px;
				}
				.title2 {
					font-weight: 700;
					font-size: 54px;
					margin-bottom: 29px;
				}
				.font2 {
					font-weight: 400;
					font-size: 20px;
					margin-bottom: 106px;
					width: 800px;
				}
				.logo2 {
					width: 422px;
					height: 290px;
					margin-bottom: 138px;
				}
				.title3 {
					font-weight: 600;
					font-size: 32px;
					margin-bottom: 29px;
				}
				.font3 {
					font-weight: 400;
					font-size: 20px;
					margin-bottom: 32px;
					width: 700px;
				}
				.box1 {
					width: 100%;
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					.item {
						width: 400px;
						display: flex;
						flex-direction: column;
						align-items: center;
						margin-bottom: 40px;
						img {
							width: 400px;
							height: 220px;
							margin-bottom: 20px;
						}
					}
				}
				.box2 {
					width: 100%;
					height: 68px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 40px;
					.left {
						width: 276px;
						height: 60px;
						font-size: 54px;
						font-weight: 700;
					}
					.right {
						width: 298px;
						height: 68px;
						display: flex;
						align-items: center;
						justify-content: center;
						border: 0;
						background: rgba(253, 170, 51, 1);
						border-radius: 3px;
						font-weight: 600;
						font-size: 18px;
						color: #000000;
						.down {
							margin-right: 6px;
						}
						.h5down {
							display: none;
						}
					}
				}
				.box3 {
					width: 100%;
					height: 198px;
					background: #1e1e1e;
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 30px;
					.left {
						width: 290px;
						height: 104px;
						.top {
							font-size: 22px;
							font-weight: 400;
							margin-bottom: 22px;
						}
						.bottom {
							display: flex;
							justify-content: space-between;

							.button {
								width: 134px;
								height: 54px;
								font-size: 18px;
								font-weight: 600;
								border-radius: 40px;
								border: 1px solid #fdaa33;
								text-align: center;
								line-height: 54px;
								color: #fdaa33;
							}
						}
					}
					.right {
						width: 160px;
						height: 74px;
					}
				}
			}
			.navright {
				position: sticky;
				top: 180px;
				right: 262px;
				width: 400px;
				height: 330px;
				border-left: 1px solid rgba(50, 50, 50, 1);
				.active {
					border-left: 1px solid #fdaa33;
					background: #1e1e1e;
				}
				.item {
					width: 100%;
					height: 80px;
					padding: 27.5px 20px;
					color: #828282;
				}
				.item_active {
					border-left: 1px solid #fdaa33;
					background: #1e1e1e;
				}
				.item:hover {
					border-left: 1px solid #fdaa33;
					background: #1e1e1e;
				}
			}
		}
	}
}
</style>
